import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from '../components/seo';
import '../styles/theme.scss';

const RealProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulRealProject(sort: { fields: category }) {
        edges {
          node {
            projectName
            category
            slug
            description {
              json
            }
            featuredImage {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Real World Projects | Web Development | Web Design" />
      <Container fluid>
        <Row className="pageHeader">
          <Col>
            <h1>REAL WORLD PROJECTS</h1>
          </Col>
          <Col>
            <h5>
              This page contains the real world projects that I have done after
              graduation.
            </h5>
          </Col>
        </Row>
        <Row className="real-project-wrapper">
          {data.allContentfulRealProject.edges.map((edge, index) => {
            return (
              <Col md="6" sm="12" key={index}>
                <div className="real-project-img">
                  <img
                    src={edge.node.featuredImage.file.url}
                    alt={edge.node.featuredImage.title}
                  />
                </div>
                <div className="real-project-desc">
                  <Link to={`/real-projects/${edge.node.slug}`}>
                    <h4>{edge.node.projectName}</h4>
                  </Link>

                  {documentToReactComponents(edge.node.description.json)}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};
export default RealProjects;
